import ApiERP from '@/core/conectores/ApiERP';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IDTOPessoa } from '@/models/DTO/Cadastros/Pessoas/IDTOPessoa';

/**
 * Serviço importação de pessoas
 * Fornece todas regras de negócios e lógicas relacionado a importação de pessoas.
 */
class ServicoImportacaoPessoas {
    endPoint = 'importacao-pessoas';

    apiERP = new ApiERP();

    public requisicaoSistema():void {
      this.apiERP = new ApiERP(true);
    }

    public async obterArquivoPadraoPessoas(): Promise<string> {
      const result: any = await this.apiERP.get(`${this.endPoint}/pessoas/arquivo-template`);
      return result.data;
    }

    public async lerArquivoPessoas(empresa: number, arquivo: any): Promise<IDTOPessoa[]> {
      const data = new FormData();
      data.append('arquivo', arquivo);

      const result: any = await this.apiERP.upload(`${this.endPoint}/pessoas/ler-arquivo/${empresa}`, data);
      return result.data;
    }

    public async importarPessoas(empresa: number, relacaoPrincipal: string, pessoas: IDTOPessoa[]): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}/pessoas/importar/relacao-principal/${relacaoPrincipal}/empresa/${empresa}`, pessoas);
      return result.data;
    }
}
export default ServicoImportacaoPessoas;
